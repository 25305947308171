<template>
  <div>
    <b-card class="mb-1">
      <b-form class="m-0" ref="formFilter" @submit.prevent="getData()">
        <!-- Linha 1: CPF, ID Pedido, Forma de Pagamento -->
        <div class="form-row">
          <!-- CPF Filter -->
          <b-col cols="12" md="3">
            <div class="form-group">
              <label for="">CPF</label>
              <input type="tel" v-model="filters.cpf" class="form-control" />
            </div>
          </b-col>

          <!-- ID Pedido Filter -->
          <b-col cols="12" md="3">
            <div class="form-group">
              <label for="">ID Pedido</label>
              <input type="tel" v-model="filters.id" class="form-control" />
            </div>
          </b-col>

          <!-- Payment Method Filter -->
          <b-col cols="12" md="3">
            <div class="form-group">
              <label for="">Forma de Pagamento</label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :options="optionsPaymentMethods"
                v-model="filters.method"
              >
                <span slot="no-options">Nenhum registro encontrado</span>
              </v-select>
            </div>
          </b-col>

          <!-- Return Method Filter -->
          <b-col cols="12" md="3" v-if="$route.meta.step_id === 4">
            <div class="form-group">
              <label for="">Tipo de Retorno</label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :options="optionsReturnMethod"
                v-model="filters.returnMethod"
              >
                <span slot="no-options">Nenhum registro encontrado</span>
              </v-select>
            </div>
          </b-col>
        </div>

        <!-- Linha 2: Período, Data de Início, Data de Conclusão -->
        <div class="form-row">
          <!-- Range Date Filter -->
          <b-col cols="12" md="4">
            <div class="form-group mb-md-0">
              <label for="">Período</label>
              <flat-pickr
                v-model="filters.rangeDate"
                class="form-control"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </b-col>

          <!-- Request Date Filter -->
          <b-col cols="12" md="4">
            <div class="form-group mb-md-0">
              <label for="">Data de Início</label>
              <flat-pickr
                v-model="filters.requestDate"
                class="form-control"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </b-col>

          <!-- Completion Date Filter -->
          <b-col cols="12" md="4" v-if="$route.meta.step_id === 4">
            <div class="form-group mb-md-0">
              <label for="">Data de Conclusão</label>
              <flat-pickr
                v-model="filters.completionDate"
                class="form-control"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </b-col>
        </div>

        <!-- Botão de Submit -->
        <div class="form-row">
          <b-col cols="12" md="2" class="ml-auto">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="margin-top: 20px"
            >
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <feather-icon
                  icon="SearchIcon"
                  class="cursor-pointer cursor"
                  size="16"
                />
              </div>
            </button>
          </b-col>
        </div>
      </b-form>
    </b-card>

    <b-card no-body v-if="!submitedFilter" class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="filters.perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="text-right mt-2 mt-md-0"
            v-if="
              $route.meta.step_id !== 4 &&
              $can($route.meta.cancel_action, 'Financeiro - ADM')
            "
          >
            <b-button
              v-if="$route.meta.step_id === 2"
              variant="success"
              :disabled="selectedItems.length === 0"
              @click="showReturnMethodModal()"
            >
              {{ $route.meta.buttonText }}
            </b-button>
            <b-button
              v-else
              variant="success"
              :disabled="selectedItems.length === 0"
              @click="showConfirmationModal()"
            >
              {{ $route.meta.buttonText }}
            </b-button>
          </b-col>
        </b-form-row>
      </div>

      <!-- Choose Return Modal -->
      <b-modal
        id="return-method-modal"
        ref="returnMethodModal"
        title="Escolher Método de Retorno"
        hide-footer
        modal-class="modal-dark"
        centered
        no-close-on-backdrop
      >
        <div class="my-3">
          <p>Selecione o método de retorno para os pedidos selecionados:</p>
          <v-select
            label="title"
            item-text="title"
            item-value="code"
            :options="optionsReturnMethod"
            v-model="selectedReturnMethod"
          >
            <span slot="no-options"> Nenhum registro encontrado </span>
          </v-select>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <b-button variant="secondary" @click="closeReturnMethodModal"
            >Cancelar</b-button
          >
          <b-button
            variant="primary"
            class="ml-2"
            :disabled="!selectedReturnMethod"
            @click="markOrders"
          >
            Confirmar
          </b-button>
        </div>
      </b-modal>

      <!-- Confirmation Modal -->
      <b-modal
        id="confirmation-modal"
        ref="confirmationModal"
        :title="$route.meta.buttonText + '?'"
        hide-footer
        modal-class="modal-dark"
        centered
        no-close-on-backdrop
      >
        <div class="my-2">
          <p>
            Você tem certeza de que deseja
            <strong>{{ $route.meta.buttonText }}</strong> os pedidos
            selecionados?
          </p>
        </div>
        <div class="d-flex justify-content-end">
          <b-button variant="secondary" @click="closeModal">Cancelar</b-button>
          <b-button
            variant="success"
            :disabled="isLoading"
            class="ml-2"
            @click="markOrders()"
          >
            <div v-if="isLoading">
              <b-spinner small label="Loading..."></b-spinner>
            </div>
            <div v-else>Confirmar</div>
          </b-button>
        </div>
      </b-modal>

      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        selectable
        :select-mode="'multi'"
        no-select-on-click
        :selected-variant="'primary'"
        @row-selected="onRowSelected"
      >
        <!-- Checkbox Column -->
        <template #cell(selected)="data">
          <b-form-checkbox
            v-model="data.selected"
            :value="true"
            :unchecked-value="false"
            class="mb-0"
            @change="toggleRowSelection(data.item)"
          ></b-form-checkbox>
        </template>

        <!-- Customer Column -->
        <template #cell(customer_name)="data">
          <b-media vertical-align="center">
            <span class="d-block text-nowrap">
              {{ data.item.customer_name }}
            </span>
            <p class="mb-0" style="margin-top: 4px">
              <strong><i class="bi bi-credit-card-2-front"></i></strong>
              {{ data.item.customer_cpf }} -
              <strong class="text-purple">
                <i class="bi bi-box"></i> {{ data.item.items }}
              </strong>
              <b-badge
                class="ml-1"
                v-if="data.item.origin_name"
                variant="light-dark"
              >
                {{ data.item.origin_name }}
              </b-badge>
            </p>
          </b-media>
        </template>

        <!-- Payment Method Column -->
        <template #cell(payment_method)="data">
          <i
            style="font-size: 1.3rem"
            :class="`bi ${data.item.payment_method_icon}`"
          ></i>
        </template>

        <!-- Step Column -->
        <template #cell(step_name)="data">
          <b-badge
            class="d-block"
            :variant="getStepBadgeVariant(data.item.step_name)"
          >
            {{ data.item.step_name }}
          </b-badge>
        </template>

        <!-- Return Method Column -->
        <template #cell(return_method_name)="data">
          <b-badge
            class="d-block"
            v-if="data.item.return_method_name"
            :variant="getReturnMethodBadgeVariant(data.item.return_method_name)"
          >
            {{ data.item.return_method_name }}
          </b-badge>
        </template>

        <!-- Status Column -->
        <template #cell(status_name)="data">
          <b-badge class="d-block" :variant="`light-${data.item.status_class}`">
            {{ data.item.status_name }}
          </b-badge>
        </template>

        <!-- Total Column -->
        <template #cell(total)="data">
          {{ data.item.total | toCurrency }}
        </template>

        <!-- Total Refund Column -->
        <template #cell(total_refund)="data">
          <b-badge class="d-block" variant="light-danger">
            {{ data.item.total_refund | toCurrency }}
          </b-badge>
        </template>

        <!-- Actions Column -->
        <template #cell(action)="data">
          <router-link
            :to="{
              name: 'transactions-orders-preview',
              params: { uuid: data.item.uuid },
            }"
            v-if="$can('Pedido - Visualizar', 'Comercial')"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="cursor-pointer cursor mr-1"
            />
          </router-link>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page.sync="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-card v-else class="mb-0">
      <div class="row">
        <div class="col-md-12 text-center">
          <p class="my-0 py-3 d-flex-center">
            <b-spinner small variant="primary" class="mr-50" /> Buscando
            pedidos...
          </p>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BIcon,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BTable,
  BButton,
  BPagination,
  BMedia,
  BTooltip,
  BBadge,
  BFormRow,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  components: {
    flatPickr,
    CustomPaginateTable,
    BCard,
    BFormRow,
    BForm,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    BButton,
    BPagination,
    BMedia,
    BTooltip,
    BBadge,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      filters: {
        rangeDate: "",
        requestDate: `${moment()
          .startOf("month")
          .format("YYYY-MM-DD")} até ${moment()
          .endOf("month")
          .format("YYYY-MM-DD")}`, // Novo filtro
        completionDate: "", // Novo filtro
        returnMethod: "",
        status: "",
        method: "",
        cpf: "",
        product: "",
        id: "",
        categories: "",
        step_id: this.$route.meta.step_id || 1,
        currentPage: 1,
        perPage: 100,
      },
      selectedItems: [],
      selectedReturnMethod: null,
      submitedFilter: false,
      filter: null,
      filterOn: [],
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      fields: [
        {
          key: "selected",
          label: "",
          class: "text-center",
          thStyle: "width: 40px",
        },
        {
          key: "id",
          label: "ID",
          class: "text-left",
        },
        {
          key: "customer_name",
          label: "Cliente",
          class: "text-left",
        },
        {
          key: "status_name",
          label: "Status",
          class: "text-center",
        },
        {
          key: "step_name",
          label: "Etapa",
          class: "text-center",
        },
        {
          key: "return_method_name",
          label: "Tipo Retorno",
          class: "text-center",
        },
        {
          key: "payment_method",
          label: "Forma",
          class: "text-center",
        },
        {
          key: "total",
          label: "total",
          class: "text-center",
        },
        {
          key: "total_refund",
          label: "Total Devolvido",
          class: "text-center",
          visible: false,
        },
        {
          key: "created_at",
          label: "Data de Criação",
          class: "text-center",
        },
        {
          key: "request_date",
          label: "Data de Solicitação",
          class: "text-center",
        },
        {
          key: "completion_date",
          label: "Data de Conclusão",
          class: "text-center",
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      perPageOptions: [
        {
          title: "15",
          value: 15,
        },
        {
          title: "50",
          value: 50,
        },
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      optionsPaymentMethods: [],
      optionsStatus: [],
      optionsProducts: [],
      optionsProductCategories: [],
      optionsReturnMethod: [],
    };
  },
  methods: {
    getStepBadgeVariant(stepName) {
      switch (stepName) {
        case "Solicitado":
          return "primary";
        case "Conferido":
          return "info";
        case "Aprovado":
          return "success";
        case "Efetivado":
          return "warning";
        default:
          return "secondary";
      }
    },
    getReturnMethodBadgeVariant(returnMethodName) {
      switch (returnMethodName) {
        case "Estorno":
          return "primary";
        case "Depósito":
          return "info";
        default:
          return "secondary";
      }
    },
    async fetchProductCategories() {
      this.optionsProductCategories = [];
      this.$store.dispatch("Product/categories").then((res) => {
        this.optionsProductCategories = res.data;
      });
    },
    async fetchProducts(term) {
      this.optionsProducts = [];
      if (term.length > 2) {
        this.$store.dispatch("Product/search", { term: term }).then((res) => {
          this.optionsProducts = res;
        });
      }
    },
    async fetchStatus() {
      this.$store.dispatch("Order/status").then((res) => {
        this.optionsStatus = res;
      });
    },
    async fetchPaymentMethods() {
      this.$store.dispatch("Order/paymentMethods").then((res) => {
        this.optionsPaymentMethods = res;
      });
    },
    async fetchReturnMethods() {
      this.$store.dispatch("Order/returnMethods").then((res) => {
        this.optionsReturnMethod = res;
      });
    },
    setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
      this.getData();
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.currentPage = 1;
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.currentPage = 1;
    },
    showConfirmationModal() {
      if (this.selectedItems.length === 0) {
        console.log("Nenhum pedido selecionado.");
        this.$bvToast.toast("Nenhum pedido selecionado.", {
          title: "Erro",
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.$refs.confirmationModal.show();
    },
    closeModal() {
      this.$refs.confirmationModal.hide();
    },
    onRowSelected(items) {
      this.selectedItems = items;
    },
    toggleRowSelection(item) {
      const isSelected = this.selectedItems.some(
        (selected) => selected.id === item.id
      );
      if (isSelected) {
        this.selectedItems = this.selectedItems.filter(
          (selected) => selected.id !== item.id
        );
      } else {
        this.selectedItems.push(item);
      }
    },
    showReturnMethodModal() {
      this.$refs.returnMethodModal.show();
    },
    closeReturnMethodModal() {
      this.selectedReturnMethod = null;
      this.$refs.returnMethodModal.hide();
    },
    async markOrders() {
      if (this.selectedItems.length === 0) {
        this.$bvToast.toast("Nenhum pedido selecionado.", {
          title: "Erro",
          variant: "danger",
          solid: true,
        });
        return;
      }

      try {
        this.isLoading = true;
        const orderIds = this.selectedItems.map((order) => order.id);

        await this.$store.dispatch("Order/updateCancelledState", {
          ids: orderIds,
          step_id: this.$route.meta.step_id + 1,
          return_method_id: this.selectedReturnMethod
            ? this.selectedReturnMethod.code
            : null,
        });

        this.getData();

        this.$bvToast.toast("Pedidos atualizados com sucesso!", {
          title: "Sucesso",
          variant: "success",
          solid: true,
        });

        this.selectedItems = [];
      } catch (error) {
        this.$bvToast.toast("Erro ao atualizar pedidos.", {
          title: "Erro",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
        this.closeReturnMethodModal();
        this.closeModal();
      }
    },
    async getData() {
      this.submitedFilter = true;

      let filtersSearch = {
        rangeDate: this.filters.rangeDate ?? "",
        requestDate: this.filters.requestDate ?? "",
        completionDate: this.filters.completionDate ?? "",
        status: this.filters.status ? this.filters.status.code : "",
        method: this.filters.method ? this.filters.method.code : "",
        returnMethod: this.filters.returnMethod
          ? this.filters.returnMethod.code
          : "",
        cancelation: 1,
        product: this.filters.product ? this.filters.product.code : "",
        coupon: this.filters.coupon ? this.filters.coupon.code : "",
        category: this.filters.category ? this.filters.category.code : "",
        cpf: this.filters.cpf ?? "",
        id: this.filters.id ?? "",
        step_id: this.filters.step_id,
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
      };

      this.$store
        .dispatch("Order/all", filtersSearch)
        .then((orders) => {
          if (orders) {
            this.dataItems = orders.data;
            this.rowsTable = orders.data.length;
            this.totalRows = orders.meta.total;
            this.filters.currentPage = orders.meta.current_page;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
    methodIcon(item) {
      switch (item) {
        case 1:
          return "credit-card";
        case 2:
          return "upc-scan";
        case 3:
          return "qr-code-scan";
        default:
          return "arrow-down-up";
      }
    },
  },
  directives: {
    Ripple,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  watch: {
    $route(to, from) {
      this.filters.step_id = to.meta.step_id || 1;
      this.filters.currentPage = 1;
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.fetchProductCategories();
    this.fetchStatus();
    this.fetchPaymentMethods();
    this.fetchReturnMethods();

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.swal2-icon-content {
  i {
    font-size: 2rem;
    color: #28c76f;
  }
}

.swal2-icon-show {
  border-color: #28c76f;
}
</style>
